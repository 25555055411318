<!--停车页面-->
<template>
	<div class="parking-box">
		<div class="flex ac jb fw">
			<!-- 客源地省内 -->
			<div class="left1 park-box">
				<div class="flex ac jb">
					<div class="left-img">
						<img class="title-img" src="../assets/parking/9-title-shengnei@2x.png" alt="c">
					</div>
					<div class="right-select2 all-date-box flex ac jb">
						<el-cascader v-model="Jqvalue" placeholder="景区停车场选择" size="mini" popper-class="popper-select"
							:options="Jqoptions2"
							:props="{ children: 'list',label:'name',value:'id',multiple:true,emitPath:false}"
							:collapse-tags="true" :show-all-levels="false" @change="changeJq(1)"></el-cascader>

						<div class="left_date all-date-box" style="margin-left: 10px">
							<el-date-picker v-model="date1" type="daterange" :clearable="false" range-separator="-"
								start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
								value-format="yyyy-MM-dd" placement="bottom-start" size="small" style="width: 100%"
								@change="handleDate1"></el-date-picker>
						</div>

						<el-popover placement="bottom" width="480" v-model="kjvisible1">
							<div>
								<div class="chooseYearBox flex ac">
									<div style="margin-right: 5px">选择年份:</div>
									<el-date-picker v-model="yearValue1" type="year" placeholder="选择年"
										@change="chooseYaer1" format="yyyy" value-format="yyyy">
									</el-date-picker>
								</div>
								<div class="month-box flex fw" style="margin-top: 5px">
									<div style="margin-right: 5px">选择月份:</div>
									<div style="width: 80%">
										<el-checkbox-group v-model="monthValue1" size="mini" :disabled="checkDisable"
											:max="1" @change="handleMonth">
											<el-checkbox label="01" border>一月</el-checkbox>
											<el-checkbox label="02" border>二月</el-checkbox>
											<el-checkbox label="03" border>三月</el-checkbox>
											<el-checkbox label="04" border>四月</el-checkbox>
											<el-checkbox label="05" border>五月</el-checkbox>
											<el-checkbox label="06" border>六月</el-checkbox>
											<el-checkbox label="07" border>七月</el-checkbox>
											<el-checkbox label="08" border>八月</el-checkbox>
											<el-checkbox label="09" border>九月</el-checkbox>
											<el-checkbox label="10" border>十月</el-checkbox>
											<el-checkbox label="11" border>十一月</el-checkbox>
											<el-checkbox label="12" border>十二月</el-checkbox>
										</el-checkbox-group>
									</div>

								</div>
								<div class="month-box flex fw">
									<div style="margin-right: 5px">选择节假日:</div>
									<div style="width: 80%">
										<el-checkbox-group v-model="holidayValue1" size="mini" :disabled="checkDisable2"
											:max="1" @change="handleHoliday">
											<div class="flex ac fw">
												<div v-for="item in holidayList" :key="item.id"
													style="margin-right: 5px">
													<el-checkbox :label="item.name" border />
												</div>
											</div>
										</el-checkbox-group>
									</div>
								</div>
							</div>
							<div style="text-align: right; margin: 0">
								<el-button size="mini" type="text" @click="cannelKj(1)">取消</el-button>
								<el-button type="primary" size="mini" @click="sumbitKj(1)">确定</el-button>
							</div>
							<div class="kj-box" slot="reference">
								<p v-if="monthValue1.length>0">{{yearValue1}} {{monthValue1.toString()}}</p>
								<p v-else-if="holidayValue1.length>0">{{yearValue1}} {{holidayValue1.toString()}}</p>
								<p v-else>快捷筛选</p>
							</div>
						</el-popover>
					</div>
				</div>
				<div class="info-box flex ac jb">
					<div class="info1-box">
						<div class="echart_r">
							<div class="echart_r_box" v-for="(item1, index1) in xData1.slice(0,6)" :key="index1">
								<div class="echart_r_box1" :style="show1(index1, '4px')">{{ item1.name }}</div>
								<div class="echart_r_box2" :style="show1(index1, '1px')">{{ item1.value }}
									<span>({{ item1.percent }}%)</span>
								</div>
							</div>
						</div>
					</div>
					<div class="info2-box">
						<dv-loading v-if="loading1" style="height: 237px">Loading...</dv-loading>
						<div v-else class="right_echart_box">
							<div class="echart_l">
								<Echart :options="options1" id="chart1" height="268px" width="268px"></Echart>
							</div>
						</div>
					</div>
					<div class="info3-box">
						<div class="echart_r" v-if="xData1.length>6">
							<div class="echart_r_box" v-for="(item1, index1) in xData1.slice(6,xData1.length)"
								:key="index1">
								<div class="echart_r_box1" :style="show1(index1+6, '4px')">{{ item1.name }}</div>
								<div class="echart_r_box2" :style="show1(index1+6, '1px')">{{ item1.value }}
									<span>({{item1.percent}}%)</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 客源地省外 -->
			<div class="left2 park-box">
				<div class="flex ac jb">
					<div class="left-img">
						<img class="title-img" src="../assets/parking/9-title-shengwai@2x.png" alt="c">
					</div>
					<div class="right-select2 all-date-box flex ac jb">
						<el-cascader v-model="Jqvalue2" placeholder="景区停车场选择" size="mini" :options="Jqoptions2"
							:props="{ children: 'list',label:'name',value:'id',multiple:true,emitPath:false}"
							:collapse-tags="true" :show-all-levels="false" @change="changeJq2()"></el-cascader>
						<div class="left_date all-date-box" style="margin-left: 10px">
							<el-date-picker v-model="date2" type="daterange" range-separator="-"
								start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
								value-format="yyyy-MM-dd" placement="bottom-start" size="small" :clearable="false"
								style="width: 100%" @change="handleDate2"></el-date-picker>
						</div>

						<el-popover placement="bottom" width="480" v-model="kjvisible2">
							<div>
								<div class="chooseYearBox flex ac">
									<div style="margin-right: 5px">选择年份:</div>
									<el-date-picker v-model="yearValue2" type="year" placeholder="选择年"
										@change="chooseYaer2" format="yyyy" value-format="yyyy">
									</el-date-picker>
								</div>
								<div class="month-box flex fw" style="margin-top: 5px">
									<div style="margin-right: 5px">选择月份:</div>
									<div style="width: 80%">
										<el-checkbox-group v-model="monthValue2" size="mini" :disabled="checkDisable3"
											:max="1" @change="handleMonth2">
											<el-checkbox label="01" border>一月</el-checkbox>
											<el-checkbox label="02" border>二月</el-checkbox>
											<el-checkbox label="03" border>三月</el-checkbox>
											<el-checkbox label="04" border>四月</el-checkbox>
											<el-checkbox label="05" border>五月</el-checkbox>
											<el-checkbox label="06" border>六月</el-checkbox>
											<el-checkbox label="07" border>七月</el-checkbox>
											<el-checkbox label="08" border>八月</el-checkbox>
											<el-checkbox label="09" border>九月</el-checkbox>
											<el-checkbox label="10" border>十月</el-checkbox>
											<el-checkbox label="11" border>十一月</el-checkbox>
											<el-checkbox label="12" border>十二月</el-checkbox>
										</el-checkbox-group>
									</div>

								</div>
								<div class="month-box flex fw">
									<div style="margin-right: 5px">选择节假日:</div>
									<div style="width: 80%">
										<el-checkbox-group v-model="holidayValue2" size="mini" :disabled="checkDisable4"
											:max="1" @change="handleHoliday2">
											<div class="flex ac fw">
												<div v-for="item in holidayList" :key="item.id"
													style="margin-right: 5px">
													<el-checkbox :label="item.name" border />
												</div>
											</div>
										</el-checkbox-group>
									</div>
								</div>
							</div>
							<div style="text-align: right; margin: 0">
								<el-button size="mini" type="text" @click="cannelKj(2)">取消</el-button>
								<el-button type="primary" size="mini" @click="sumbitKj(2)">确定</el-button>
							</div>
							<div class="kj-box" slot="reference">
								<p v-if="monthValue2.length>0">{{yearValue2}} {{monthValue2.toString()}}</p>
								<p v-else-if="holidayValue2.length>0">{{yearValue2}} {{holidayValue2.toString()}}</p>
								<p v-else>快捷筛选</p>
							</div>
						</el-popover>
					</div>
				</div>
				<div class="info-box flex ac jb">
					<div class="info1-box">
						<div class="echart_r">
							<div class="echart_r_box" v-for="(item1, index1) in xData2.slice(0,6)" :key="index1">
								<div class="echart_r_box1" :style="show1(index1, '4px')">{{ item1.name }}</div>
								<div class="echart_r_box2" :style="show1(index1, '1px')">{{ item1.value }}
									<span>({{ item1.percent }}%)</span>
								</div>
							</div>
						</div>
					</div>
					<div class="info2-box">
						<dv-loading v-if="loading2" style="height: 237px">Loading...</dv-loading>
						<div v-else class="right_echart_box">
							<div class="echart_l">
								<Echart :options="options2" id="chart2" height="268px" width="268px"></Echart>
							</div>
						</div>
					</div>
					<div class="info3-box">
						<div class="echart_r" v-if="xData2.length>6">
							<div class="echart_r_box" v-for="(item1, index1) in xData2.slice(6,xData2.length)"
								:key="index1">
								<div class="echart_r_box1" :style="show1(index1+6, '4px')">{{ item1.name }}</div>
								<div class="echart_r_box2" :style="show1(index1+6, '1px')">{{ item1.value }}
									<span>({{item1.percent}}%)</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 车辆进出分析 -->
			<div class="bottom1 bottom-box park-box mt23">
				<div class="box-head">
					<div class="flex ac jb">
						<div class="left-img">
							<img class="title-img" src="../assets/parking/9-title-jinchu@2x.png" alt="c">
						</div>
					</div>
					<div class="flex ac jb">
						<div class="right-select2 all-date-box flex ac jb">
							<el-cascader v-model="Jqvalue3" placeholder="景区停车场选择" size="mini" :options="Jqoptions2"
								:props="{ children: 'list',label:'name',value:'id',multiple:true,emitPath:false}"
								:collapse-tags="true" :show-all-levels="false" @change="changeJq(3)"></el-cascader>
							<div class="left_date all-date-box" style="margin-left: 10px">
								<el-date-picker v-model="date3" type="daterange" :clearable="false" range-separator="-"
									start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
									value-format="yyyy-MM-dd" placement="bottom-start" size="small" style="width: 100%"
									@change="handleDate3"></el-date-picker>
							</div>

							<el-popover placement="bottom" width="480" v-model="kjvisible3">
								<div>
									<div class="chooseYearBox flex ac">
										<div style="margin-right: 5px">选择年份:</div>
										<el-date-picker v-model="yearValue3" type="year" placeholder="选择年"
											@change="chooseYaer3" format="yyyy" value-format="yyyy">
										</el-date-picker>
									</div>
									<div class="month-box flex fw" style="margin-top: 5px">
										<div style="margin-right: 5px">选择月份:</div>
										<div style="width: 80%">
											<el-checkbox-group v-model="monthValue3" size="mini"
												:disabled="checkDisable5" :max="1" @change="handleMonth3">
												<el-checkbox label="01" border>一月</el-checkbox>
												<el-checkbox label="02" border>二月</el-checkbox>
												<el-checkbox label="03" border>三月</el-checkbox>
												<el-checkbox label="04" border>四月</el-checkbox>
												<el-checkbox label="05" border>五月</el-checkbox>
												<el-checkbox label="06" border>六月</el-checkbox>
												<el-checkbox label="07" border>七月</el-checkbox>
												<el-checkbox label="08" border>八月</el-checkbox>
												<el-checkbox label="09" border>九月</el-checkbox>
												<el-checkbox label="10" border>十月</el-checkbox>
												<el-checkbox label="11" border>十一月</el-checkbox>
												<el-checkbox label="12" border>十二月</el-checkbox>
											</el-checkbox-group>
										</div>

									</div>
									<div class="month-box flex fw">
										<div style="margin-right: 5px">选择节假日:</div>
										<div style="width: 80%">
											<el-checkbox-group v-model="holidayValue3" size="mini"
												:disabled="checkDisable6" :max="1" @change="handleHoliday3">
												<div class="flex ac fw">
													<div v-for="item in holidayList" :key="item.id"
														style="margin-right: 5px">
														<el-checkbox :label="item.name" border />
													</div>
												</div>
											</el-checkbox-group>
										</div>
									</div>
								</div>
								<div style="text-align: right; margin: 0">
									<el-button size="mini" type="text" @click="cannelKj(3)">取消</el-button>
									<el-button type="primary" size="mini" @click="sumbitKj(3)">确定</el-button>
								</div>
								<div class="kj-box" slot="reference">
									<p v-if="monthValue3.length>0">{{yearValue3}} {{monthValue3.toString()}}</p>
									<p v-else-if="holidayValue3.length>0">{{yearValue3}} {{holidayValue3.toString()}}
									</p>
									<p v-else>快捷筛选</p>
								</div>
							</el-popover>
						</div>
					</div>
				</div>
				<div class="info-box flex ac jb">
					<div class="echart_l">
						<Echart :options="options3" id="chart3" height="260px" width="560px"></Echart>
					</div>
				</div>
			</div>

			<!-- 停车时长分析 -->
			<div class="bottom2 bottom-box park-box mt23">
				<div class="box-head">
					<div class="flex ac jb">
						<div class="left-img">
							<img class="title-img" src="../assets/parking/9-title-shichang@2x.png" alt="c">
						</div>
					</div>
					<div class="flex ac jb">
						<div class="right-select2 all-date-box flex ac jb">
							<el-cascader v-model="Jqvalue4" placeholder="景区停车场选择" size="mini" :options="Jqoptions2"
								:props="{ children: 'list',label:'name',value:'id',multiple:true,emitPath:false}"
								:collapse-tags="true" :show-all-levels="false" @change="changeJq(4)"></el-cascader>
							<div class="left_date all-date-box" style="margin-left: 10px">
								<el-date-picker v-model="date4" type="daterange" :clearable="false" range-separator="-"
									start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
									value-format="yyyy-MM-dd" placement="bottom-start" size="small" style="width: 100%"
									@change="handleDate4"></el-date-picker>
							</div>
							<el-popover placement="bottom" width="480" v-model="kjvisible4">
								<div>
									<div class="chooseYearBox flex ac">
										<div style="margin-right: 5px">选择年份:</div>
										<el-date-picker v-model="yearValue4" type="year" placeholder="选择年"
											@change="chooseYaer4" format="yyyy" value-format="yyyy">
										</el-date-picker>
									</div>
									<div class="month-box flex fw" style="margin-top: 5px">
										<div style="margin-right: 5px">选择月份:</div>
										<div style="width: 80%">
											<el-checkbox-group v-model="monthValue4" size="mini"
												:disabled="checkDisable7" :max="1" @change="handleMonth4">
												<el-checkbox label="01" border>一月</el-checkbox>
												<el-checkbox label="02" border>二月</el-checkbox>
												<el-checkbox label="03" border>三月</el-checkbox>
												<el-checkbox label="04" border>四月</el-checkbox>
												<el-checkbox label="05" border>五月</el-checkbox>
												<el-checkbox label="06" border>六月</el-checkbox>
												<el-checkbox label="07" border>七月</el-checkbox>
												<el-checkbox label="08" border>八月</el-checkbox>
												<el-checkbox label="09" border>九月</el-checkbox>
												<el-checkbox label="10" border>十月</el-checkbox>
												<el-checkbox label="11" border>十一月</el-checkbox>
												<el-checkbox label="12" border>十二月</el-checkbox>
											</el-checkbox-group>
										</div>

									</div>
									<div class="month-box flex fw">
										<div style="margin-right: 5px">选择节假日:</div>
										<div style="width: 80%">
											<el-checkbox-group v-model="holidayValue4" size="mini"
												:disabled="checkDisable8" :max="1" @change="handleHoliday4">
												<div class="flex ac fw">
													<div v-for="item in holidayList" :key="item.id"
														style="margin-right: 5px">
														<el-checkbox :label="item.name" border />
													</div>
												</div>
											</el-checkbox-group>
										</div>
									</div>
								</div>
								<div style="text-align: right; margin: 0">
									<el-button size="mini" type="text" @click="cannelKj(4)">取消</el-button>
									<el-button type="primary" size="mini" @click="sumbitKj(4)">确定</el-button>
								</div>
								<div class="kj-box" slot="reference">
									<p v-if="monthValue4.length>0">{{yearValue4}} {{monthValue4.toString()}}</p>
									<p v-else-if="holidayValue4.length>0">{{yearValue4}} {{holidayValue4.toString()}}
									</p>
									<p v-else>快捷筛选</p>
								</div>
							</el-popover>
						</div>
					</div>
				</div>
				<div class="info-box flex ac jb">
					<!-- <div class="info1-box">
						<div class="echart_r">
							<div class="echart_r_box" v-for="(item1, index1) in tsscData.slice(0,2)" :key="index1">
								<div class="echart_r_box1" :style="show1(index1, '4px')">{{ item1.name }}</div>
								<div class="echart_r_box2" :style="show1(index1, '1px')">{{ item1.value }}
									<span>({{ item1.percent }}%)</span></div>
							</div>
						</div>
					</div> -->
					<div class="info2-box">
						<dv-loading v-if="loading4" style="height: 237px">Loading...</dv-loading>
						<div v-else class="right_echart_box">
							<div class="echart_l">
								<Echart :options="options4" id="chart4" height="248px" width="248px"></Echart>
							</div>
						</div>
					</div>
					<div class="info3-box">
						<div class="echart_r" v-if="tsscData.length>2">
							<div class="echart_r_box" v-for="(item1, index1) in tsscData" :key="index1">
								<div class="echart_r_box1" :style="show1(index1+2, '4px')">{{ item1.name }}</div>
								<div class="echart_r_box2" :style="show1(index1+2, '1px')">{{ item1.value }}
									<span>({{item1.percent}}%)</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 多景区停车数据 -->
			<div class="bottom3 bottom-box park-box mt23">
				<div class="box-head">
					<div class="flex ac jb">
						<div class="left-img">
							<img class="title-img" src="../assets/parking/9-title-duojingqu@2x.png" alt="c">
						</div>
					</div>
					<div class="flex ac jb" style="justify-content: end;">
						<div class="right-select2 all-date-box flex ac jb">
							<div class="left_date all-date-box" style="margin-left: 10px;width: 230px;">
								<el-date-picker v-model="date5" type="daterange" :clearable="false" range-separator="-"
									start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
									value-format="yyyy-MM-dd" placement="bottom-start" size="small" style="width: 100%"
									@change="handleDate5"></el-date-picker>
							</div>
							<el-popover placement="bottom" width="480" v-model="kjvisible5">
								<div>
									<div class="chooseYearBox flex ac">
										<div style="margin-right: 5px">选择年份:</div>
										<el-date-picker v-model="yearValue5" type="year" placeholder="选择年"
											@change="chooseYaer5" format="yyyy" value-format="yyyy">
										</el-date-picker>
									</div>
									<div class="month-box flex fw" style="margin-top: 5px">
										<div style="margin-right: 5px">选择月份:</div>
										<div style="width: 80%">
											<el-checkbox-group v-model="monthValue5" size="mini"
												:disabled="checkDisable9" :max="1" @change="handleMonth5">
												<el-checkbox label="01" border>一月</el-checkbox>
												<el-checkbox label="02" border>二月</el-checkbox>
												<el-checkbox label="03" border>三月</el-checkbox>
												<el-checkbox label="04" border>四月</el-checkbox>
												<el-checkbox label="05" border>五月</el-checkbox>
												<el-checkbox label="06" border>六月</el-checkbox>
												<el-checkbox label="07" border>七月</el-checkbox>
												<el-checkbox label="08" border>八月</el-checkbox>
												<el-checkbox label="09" border>九月</el-checkbox>
												<el-checkbox label="10" border>十月</el-checkbox>
												<el-checkbox label="11" border>十一月</el-checkbox>
												<el-checkbox label="12" border>十二月</el-checkbox>
											</el-checkbox-group>
										</div>

									</div>
									<div class="month-box flex fw">
										<div style="margin-right: 5px">选择节假日:</div>
										<div style="width: 80%">
											<el-checkbox-group v-model="holidayValue5" size="mini"
												:disabled="checkDisable10" :max="1" @change="handleHoliday5">
												<div class="flex ac fw">
													<div v-for="item in holidayList" :key="item.id"
														style="margin-right: 5px">
														<el-checkbox :label="item.name" border />
													</div>
												</div>
											</el-checkbox-group>
										</div>
									</div>
								</div>
								<div style="text-align: right; margin: 0">
									<el-button size="mini" type="text" @click="cannelKj(5)">取消</el-button>
									<el-button type="primary" size="mini" @click="sumbitKj(5)">确定</el-button>
								</div>
								<div class="kj-box" slot="reference">
									<p v-if="monthValue5.length>0">{{yearValue5}} {{monthValue5.toString()}}</p>
									<p v-else-if="holidayValue5.length>0">{{yearValue5}} {{holidayValue5.toString()}}
									</p>
									<p v-else>快捷筛选</p>
								</div>
							</el-popover>
						</div>
					</div>
				</div>
				<div class="info-box flex ac jb">
					<div class="info2-box">
						<dv-loading v-if="loading5" style="height: 237px">Loading...</dv-loading>
						<div v-else class="right_echart_box">
							<div class="echart_l" style="margin-left: 10px;">
								<Echart :options="options5" id="chart5" height="228px" width="228px"></Echart>
							</div>
						</div>
					</div>
					<div class="info3-box">
						<div class="echart-r">
							<div class="echart-r-box">
								<div class="echart-r-box-item echart-r-box-top">
									<div class="echart-r-box-item-box" v-for="(item, index) in djqAreaData" :key="index">
										<div class="echart-r-box-item-color" :style="{backgroundColor: colorList[index]}"></div>
										<span class="echart-r-box-item-title">{{ item.name }}：</span>
										<span class="echart-r-box-item-num">{{ item.value }}</span>
										<span class="echart-r-box-item-percent"> ({{ item.percent }}%)</span>
									</div>
								</div>
								<div class="echart-r-box-item echart-r-box-bottom">
									<div class="echart-r-box-item-box" v-for="(item, index) in djqScenicData" :key="index">
										<div class="echart-r-box-item-color" :style="{backgroundColor: colorList[index+djqAreaData.length]}"></div>
										<span class="echart-r-box-item-title">{{ item.name }}：</span>
										<span class="echart-r-box-item-num">{{ item.value }}</span>
										<span class="echart-r-box-item-percent">({{ item.percent }}%)</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>
<script>
	import {
		formatTime
	} from "../utils/index.js";
	import Echart from '../common/echart'
	import {
		tjScenicSpotList,
		tjHolidayList,
		tjParkCity,
		tjParkProvince,
		tjParkAccessAnalysis,
		tjParkDurationAnalysis,
		tjParkMultiScenic,
		tjParkList
	} from "../api";

	export default {
		components: {
			Echart,
		},
		name: '',
		data() {
			return {
				Jqvalue: [],
				Jqvalue2: [],
				Jqoptions: [],
				Jqoptions2: [],
				date1: [],
				date2: [],
				month: formatTime(new Date(), "yyyy-MM"),
				month2: formatTime(new Date(), "yyyy-MM"),
				pickerOptions: { // 限制日期范围为一个月
					onPick: ({
						maxDate,
						minDate
					}) => {
						this.isRestart = false;
						this.pickerMinDate = minDate.getTime();
						if (maxDate) {
							this.pickerMinDate = "";
						}
					},

					disabledDate: (time) => {
						if (this.pickerMinDate !== "") {
							const one = 3 * 31 * 24 * 3600 * 1000;
							const minTime = this.pickerMinDate - one;
							const maxTime = this.pickerMinDate + one;
							return time.getTime() < minTime || time.getTime() > maxTime;
						}
					},
				},
				kjvisible1: false,
				kjvisible2: false,
				kjvisible3: false,
				kjvisible4: false,
				kjvisible5: false,
				yearValue1: new Date().getFullYear().toString(),
				monthValue1: [],
				holidayList: [],
				holidayValue1: [],
				checkDisable: false,
				checkDisable2: false,
				checkDisable3: false,
				checkDisable4: false,
				checkDisable5: false,
				checkDisable6: false,
				checkDisable7: false,
				checkDisable8: false,
				checkDisable9: false,
				checkDisable10: false,

				loading1: false,
				options1: {},
				xData1: [],


				colorList: ["#5B8FF9", "#207BF0", "#37D2FF", "#4EEB9B", "#00F4E0", "#604EEB",
					'#DF4EEB', '#EB4EBD', '#FF99C3', '#FF6F6F', '#EBA94E', '#B6D85A', '#AA4EEB'
				],
				allNum: 0,

				yearValue2: new Date().getFullYear().toString(),
				monthValue2: [],
				holidayValue2: [],
				loading2: false,
				loading4: false,
				options2: {},
				xData2: [],
				allNum2: 0,

				date3: [],
				yearValue3: new Date().getFullYear().toString(),
				Jqvalue3: [],
				monthValue3: [],
				holidayValue3: [],
				options3: {},
				xData3: [],
				enterList: [],
				leaveList: [],

				date4: [],
				yearValue4: new Date().getFullYear().toString(),
				Jqvalue4: [],
				monthValue4: [],
				holidayValue4: [],
				options4: {},
				xData4: [],
				yData4: [],
				yData42: [],
				// 停车时长分析饼状图分析
				tsscData: [],

				date5: [],
				yearValue5: new Date().getFullYear().toString(),
				Jqvalue5: [],
				monthValue5: [],
				holidayValue5: [],
				options5: {},
				xData5: [],
				yData5: [],
				// 多景区停车数据饼状图分析
				djqTotal: 0,
				djqScenicData: [],
				djqAreaData: []

			}
		},
		created() {
			this.getTjScenicSpotList()
			this.getTjScenicSpotList2()
			this.getTjHolidayList(new Date().getFullYear());
			let end = formatTime(new Date(), "yyyy-MM-dd");
			let start = formatTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 30), "yyyy-MM-dd");
			this.date1 = [start, end];
			this.date2 = [start, end];
			this.date3 = [start, end];
			this.date4 = [start, end];
			this.date5 = [start, end];
			this.getTjParkCity()
			this.getTjParkProvince()
			this.getTjParkAccessAnalysis()
			this.getTjParkDurationAnalysis()
			this.getTjParkMultiScenic()
		},
		methods: {
			// 获取景区数据
			getTjScenicSpotList() {
				tjScenicSpotList().then(res => {
					this.Jqoptions = []
					this.Jqoptions = res.data
					const obj = {
						id: 0,
						name: '全部',
					}
					this.Jqoptions.unshift(obj)
				})
			},
			// 获取景区数据
			getTjScenicSpotList2() {
				tjParkList().then(res => {
					this.Jqoptions2 = []
					this.Jqoptions2 = res.data
					const obj = {
						id: 0,
						name: '全部',
					}
					this.Jqoptions2.unshift(obj)
				})
			},
			// 获取节假日列表
			getTjHolidayList(year) {
				tjHolidayList({
					year: year
				}).then(res => {
					this.holidayList = res.data
				})
			},
			// 选择景区
			changeJq(val) {
				if (val == 1) {
					this.getTjParkCity()
				}
				if (val == 3) {
					this.getTjParkAccessAnalysis()
				}
				if (val == 4) {
					this.getTjParkDurationAnalysis()
				}
				if (val == 5) {
					// this.getTjParkDurationAnalysis()
				}
			},
			changeJq2() {
				// console.log('Jqvalue2',this.Jqvalue2)
				this.getTjParkProvince()
			},
			// 游客总量-日期
			handleDate1() {
				this.monthValue1 = []
				this.holidayValue1 = []
				this.checkDisable = false
				this.checkDisable2 = false
				this.getTjParkCity()
			},
			chooseYaer1(val) {
				this.monthValue1 = []
				this.holidayValue1 = []
				this.checkDisable = false
				this.checkDisable2 = false
				this.getTjHolidayList(val)
				if (this.monthValue1.length > 0) {
					var lasyDay = ''
					lasyDay = this.getLastDay(val, this.monthValue1.toString())
					console.log('lasyDay', lasyDay)
					this.date1 = [val + '-' + this.monthValue1.toString() + '-01', val + '-' + this.monthValue1
						.toString() + '-' + lasyDay
					];
				}
			},
			handleMonth(val) {
				if (val.length > 0) {
					this.checkDisable2 = true
					var lasyDay = ''
					lasyDay = this.getLastDay(this.yearValue1, val.toString())
					console.log('lasyDay', lasyDay)
					this.date1 = [this.yearValue1 + '-' + val.toString() + '-01', this.yearValue1 + '-' + val.toString() +
						'-' + lasyDay
					];
				} else {
					this.checkDisable2 = false
				}
			},
			handleHoliday(val) {
				if (val.length > 0) {
					this.checkDisable = true
					this.holidayList.forEach(item => {
						if (item.name == val.toString()) {
							this.date1 = [item.start_date, item.end_date];
						}
					})
				} else {
					this.checkDisable = false
				}
			},


			handleDate2() {
				this.monthValue2 = []
				this.holidayValue2 = []
				this.checkDisable3 = false
				this.checkDisable4 = false
				this.getTjParkProvince()
			},
			chooseYaer2(val) {
				this.monthValue2 = []
				this.holidayValue2 = []
				this.checkDisable3 = false
				this.checkDisable4 = false
				this.getTjHolidayList(val)
				if (this.monthValue2.length > 0) {
					var lasyDay = ''
					lasyDay = this.getLastDay(val, this.monthValue2.toString())
					console.log('lasyDay', lasyDay)
					this.date2 = [val + '-' + this.monthValue2.toString() + '-01', val + '-' + this.monthValue2
						.toString() + '-' + lasyDay
					];
				}
			},
			handleMonth2(val) {
				if (val.length > 0) {
					this.checkDisable4 = true
					var lasyDay = ''
					lasyDay = this.getLastDay(this.yearValue2, val.toString())
					console.log('lasyDay', lasyDay)
					this.date2 = [this.yearValue2 + '-' + val.toString() + '-01', this.yearValue2 + '-' + val.toString() +
						'-' + lasyDay
					];
				} else {
					this.checkDisable4 = false
				}
			},
			handleHoliday2(val) {
				if (val.length > 0) {
					this.checkDisable3 = true
					this.holidayList.forEach(item => {
						if (item.name == val.toString()) {
							this.date2 = [item.start_date, item.end_date];
						}
					})
				} else {
					this.checkDisable3 = false
				}
			},

			handleDate3() {
				this.monthValue3 = []
				this.holidayValue3 = []
				this.checkDisable5 = false
				this.checkDisable6 = false
				this.getTjParkAccessAnalysis()
			},
			chooseYaer3(val) {
				this.monthValue3 = []
				this.holidayValue3 = []
				this.checkDisable5 = false
				this.checkDisable6 = false
				this.getTjHolidayList(val)
				if (this.monthValue3.length > 0) {
					var lasyDay = ''
					lasyDay = this.getLastDay(val, this.monthValue3.toString())
					console.log('lasyDay', lasyDay)
					this.date3 = [val + '-' + this.monthValue3.toString() + '-01', val + '-' + this.monthValue3
						.toString() + '-' + lasyDay
					];
				}
			},
			handleMonth3(val) {
				if (val.length > 0) {
					this.checkDisable6 = true
					var lasyDay = ''
					lasyDay = this.getLastDay(this.yearValue3, val.toString())
					console.log('lasyDay', lasyDay)
					this.date3 = [this.yearValue3 + '-' + val.toString() + '-01', this.yearValue3 + '-' + val.toString() +
						'-' + lasyDay
					];
				} else {
					this.checkDisable6 = false
				}
			},
			handleHoliday3(val) {
				if (val.length > 0) {
					this.checkDisable5 = true
					this.holidayList.forEach(item => {
						if (item.name == val.toString()) {
							this.date3 = [item.start_date, item.end_date];
						}
					})
				} else {
					this.checkDisable5 = false
				}
			},
			handleDate4() {
				this.monthValue4 = []
				this.holidayValue4 = []
				this.checkDisable7 = false
				this.checkDisable8 = false
				this.getTjParkDurationAnalysis()
			},
			chooseYaer4(val) {
				this.monthValue4 = []
				this.holidayValue4 = []
				this.checkDisable7 = false
				this.checkDisable8 = false
				this.getTjHolidayList(val)
				if (this.monthValue4.length > 0) {
					var lasyDay = ''
					lasyDay = this.getLastDay(val, this.monthValue4.toString())
					console.log('lasyDay', lasyDay)
					this.date4 = [val + '-' + this.monthValue4.toString() + '-01', val + '-' + this.monthValue4
						.toString() + '-' + lasyDay
					];
				}
			},
			handleMonth4(val) {
				if (val.length > 0) {
					this.checkDisable8 = true
					var lasyDay = ''
					lasyDay = this.getLastDay(this.yearValue4, val.toString())
					console.log('lasyDay', lasyDay)
					this.date4 = [this.yearValue4 + '-' + val.toString() + '-01', this.yearValue4 + '-' + val.toString() +
						'-' + lasyDay
					];
				} else {
					this.checkDisable8 = false
				}
			},
			handleHoliday4(val) {
				if (val.length > 0) {
					this.checkDisable7 = true
					this.holidayList.forEach(item => {
						if (item.name == val.toString()) {
							this.date4 = [item.start_date, item.end_date];
						}
					})
				} else {
					this.checkDisable7 = false
				}
			},
			handleDate5() {
				this.monthValue5 = []
				this.holidayValue5 = []
				this.checkDisable9 = false
				this.checkDisable10 = false
				this.getTjParkMultiScenic()
			},
			chooseYaer5(val) {
				this.monthValue5 = []
				this.holidayValue5 = []
				this.checkDisable9 = false
				this.checkDisable10 = false
				this.getTjHolidayList(val)
				if (this.monthValue5.length > 0) {
					var lasyDay = ''
					lasyDay = this.getLastDay(val, this.monthValue5.toString())
					console.log('lasyDay', lasyDay)
					this.date5 = [val + '-' + this.monthValue5.toString() + '-01', val + '-' + this.monthValue5
						.toString() + '-' + lasyDay
					];
				}
			},
			handleMonth5(val) {
				if (val.length > 0) {
					this.checkDisable10 = true
					var lasyDay = ''
					lasyDay = this.getLastDay(this.yearValue5, val.toString())
					console.log('lasyDay', lasyDay)
					this.date5 = [this.yearValue5 + '-' + val.toString() + '-01', this.yearValue5 + '-' + val.toString() +
						'-' + lasyDay
					];
				} else {
					this.checkDisable10 = false
				}
			},
			handleHoliday5(val) {
				if (val.length > 0) {
					this.checkDisable9 = true
					this.holidayList.forEach(item => {
						if (item.name == val.toString()) {
							this.date5 = [item.start_date, item.end_date];
						}
					})
				} else {
					this.checkDisable9 = false
				}
			},


			//某年某月最后一天
			getLastDay(year, month) {
				var nyear = year;
				var nmonth = month++;
				if (month > 12) {
					nmonth -= 12;
					nyear++;
				}
				var n_date = new Date(nyear, nmonth, 1);
				return (new Date(n_date.getTime() - 1000 * 60 * 60 * 24)).getDate();
			},
			cannelKj(val) {
				if (val == 1) {
					this.kjvisible1 = false
				}
				if (val == 2) {
					this.kjvisible2 = false
				}
				if (val == 3) {
					this.kjvisible3 = false
				}
				if (val == 4) {
					this.kjvisible4 = false
				}
				if (val == 5) {
					this.kjvisible5 = false
				}
			},
			sumbitKj(val) {
				if (val == 1) {
					if (!this.yearValue1) {
						this.$message.error('请选择年份')
						return false
					}
					if (this.monthValue1.length == 0 && this.holidayValue1.length == 0) {
						this.$message.error('请选择月份或者节假日')
						return false
					}
					this.kjvisible1 = false
					this.getTjParkCity();
				}
				if (val == 2) {
					if (!this.yearValue2) {
						this.$message.error('请选择年份')
						return false
					}
					if (this.monthValue2.length == 0 && this.holidayValue2.length == 0) {
						this.$message.error('请选择月份或者节假日')
						return false
					}
					this.kjvisible2 = false
					this.getTjParkProvince();
				}
				if (val == 3) {
					if (!this.yearValue3) {
						this.$message.error('请选择年份')
						return false
					}
					if (this.monthValue3.length == 0 && this.holidayValue3.length == 0) {
						this.$message.error('请选择月份或者节假日')
						return false
					}
					this.kjvisible3 = false
					this.getTjParkAccessAnalysis();
				}
				if (val == 4) {
					if (!this.yearValue4) {
						this.$message.error('请选择年份')
						return false
					}
					if (this.monthValue4.length == 0 && this.holidayValue4.length == 0) {
						this.$message.error('请选择月份或者节假日')
						return false
					}
					this.kjvisible4 = false
					this.getTjParkDurationAnalysis();
				}
				if (val == 5) {
					if (!this.yearValue5) {
						this.$message.error('请选择年份')
						return false
					}
					if (this.monthValue5.length == 0 && this.holidayValue5.length == 0) {
						this.$message.error('请选择月份或者节假日')
						return false
					}
					this.kjvisible5 = false
					this.getTjParkMultiScenic();
				}
			},
			// 客源地：省内颜色
			show1(index1, width) {
				let color = "";
				color = this.colorList[index1]
				return `border-left: ${width} solid ${color};`;
			},
			// 客源地：省内
			getTjParkCity() {
				console.log('this.Jqvalue=' + this.Jqvalue)
				// let params = {
				// 	scenic_spot_id: this.Jqvalue[0] || 0,
				// 	park_id: this.Jqvalue[1] || 0,
				// 	start_date: this.date1[0] || "",
				// 	end_date: this.date1[1] || "",
				// };
				let params = {
					scenic_spot_id: 0,
					park_id: this.Jqvalue.join(','),
					start_date: this.date1[0] || "",
					end_date: this.date1[1] || "",
				};
				this.loading1 = true;
				this.xData1 = [];
				tjParkCity(params).then((res) => {
					let data = res.data.list;
					this.allNum = res.data.total || 0;
					data.forEach((item) => {
						this.xData1.push({
							name: item.city,
							value: item.num,
							percent: item.percent
						});
					});
					this.loading1 = false;
					this.getoptions1();
				});
			},
			// 客源地：省外
			getTjParkProvince() {
				let params = {
					scenic_spot_id: 0,
					park_id: this.Jqvalue2.join(','),
					start_date: this.date2[0] || "",
					end_date: this.date2[1] || "",
				};
				this.loading2 = true;
				this.xData2 = [];
				tjParkProvince(params).then((res) => {
					let data = res.data.list;
					this.allNum2 = res.data.total || 0;
					data.forEach((item) => {
						this.xData2.push({
							name: item.province,
							value: item.num,
							percent: item.percent
						});
					});
					this.loading2 = false;
					this.getoptions2();
				});
			},
			// 车辆进出分析
			getTjParkAccessAnalysis() {
				let params = {
					scenic_spot_id: 0,
					park_id: this.Jqvalue3.join(','),
					start_date: this.date3[0] || "",
					end_date: this.date3[1] || "",
				};
				this.xData3 = [];
				this.enterList = [];
				this.leaveList = [];
				tjParkAccessAnalysis(params).then((res) => {
					let data = res.data;
					data.forEach((item) => {
						this.xData3.push(item.hour);
						this.enterList.push(item.enter);
						this.leaveList.push(item.leave);
					});
					this.getoptions3();
				});
			},
			// 时长
			getTjParkDurationAnalysis() {
				let params = {
					scenic_spot_id: 0,
					park_id: this.Jqvalue4.join(','),
					start_date: this.date4[0] || "",
					end_date: this.date4[1] || "",
				};
				this.xData4 = [];
				this.yData4 = [];
				this.yData42 = [];
				this.loading4 = true
				tjParkDurationAnalysis(params).then((res) => {
					let data = res.data;
					this.tsscData = [];
					data.forEach((item) => {
						this.xData4.push(item.title)
						this.yData4.push(item.num)
						this.yData42.push(item.date)
						this.tsscData.push({
							name: item.title,
							value: item.num,
							percent: item.percent,
							total: item.total
						});
					});
					this.loading4 = false
					this.getoptions4();
				});
			},
			// 多景区
			getTjParkMultiScenic() {
				let params = {
					start_date: this.date5[0] || "",
					end_date: this.date5[1] || "",
				};
				this.loading5 = true
				tjParkMultiScenic(params).then((res) => {
					let data = res.data;
					this.djqTotal = data.total_format;
					this.djqScenicData = [];
					data.scenic_list.forEach((item) => {
						this.djqScenicData.push({
							name: item.title,
							value: item.total_count,
							percent: item.percent,
						});
					});
					this.djqAreaData = [];
					data.area_list.forEach((item) => {
						this.djqAreaData.push({
							name: item.title,
							value: item.total_count,
							percent: item.percent,
						});
					});
					this.loading5 = false
					this.getoptions5();
				});
			},
			// 客源地：省内-图表
			getoptions1() {
				this.options1 = {
					//hover气泡
					tooltip: {
						trigger: "item",
						position: "right",
						formatter: "{b}<br/>{a} : {c}辆 {d}%",
					},
					//修改图表颜色
					color: this.colorList,

					//graphic控制环形中间出现字，其中有两个对象，就是两行字
					graphic: [{
							type: "text", //控制内容为文本文字
							left: "center",
							top: "95px", //调整距离盒子高处的位置
							style: {
								fill: "#1cffff", //控制字体颜色
								text: this.allNum, //控制第一行字显示内容
								fontSize: 20,
							},
						},
						{
							type: "text",
							left: "center",
							top: "125px",
							z: 10,
							style: {
								text: "停车总数(辆)",
								fontSize: 14,
								fill: "#FFFFFF",
							},
						},
					],
					series: [{
						name: "停车数",
						type: "pie",
						radius: ["40%", "70%"],
						center: ["50%", "45%"],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: "center",
						},
						labelLine: {
							show: false,
						},
						data: this.xData1,
					}, ],
				};
			},
			// 客源地：省外-图表
			getoptions2() {
				this.options2 = {
					//hover气泡
					tooltip: {
						trigger: "item",
						position: "right",
						formatter: "{b}<br/>{a} : {c}辆 {d}%",
					},
					//修改图表颜色
					color: this.colorList,

					//graphic控制环形中间出现字，其中有两个对象，就是两行字
					graphic: [{
							type: "text", //控制内容为文本文字
							left: "center",
							top: "95px", //调整距离盒子高处的位置
							style: {
								fill: "#1cffff", //控制字体颜色
								text: this.allNum2, //控制第一行字显示内容
								fontSize: 20,
							},
						},
						{
							type: "text",
							left: "center",
							top: "125px",
							z: 10,
							style: {
								text: "停车总数(辆)",
								fontSize: 14,
								fill: "#FFFFFF",
							},
						},
					],
					series: [{
						name: "停车数",
						type: "pie",
						radius: ["40%", "70%"],
						center: ["50%", "45%"],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: "center",
						},
						labelLine: {
							show: false,
						},
						data: this.xData2,
					}, ],
				};
			},
			// 车辆进出分析
			getoptions3() {
				this.options3 = {
					tooltip: {
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						}
					},
					legend: {
						data: ['车辆入场', '车辆出场'],
						align: 'right',
						left: 10
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: this.xData3
					}],
					yAxis: [{
						type: 'value',
						name: '车辆(辆)',
						axisLabel: {
							formatter: '{value}'
						}
					}],
					series: [{
						name: '车辆入场',
						type: 'bar',
						data: this.enterList,
						itemStyle: {
							color: '#00DAEF'
						}
					}, {
						name: '车辆出场',
						type: 'bar',
						data: this.leaveList,
						itemStyle: {
							color: '#FB8046'
						}
					}]
				};
			},

			getoptions4() {
				this.options4 = {
					//hover气泡
					tooltip: {
						trigger: "item",
						position: "right",
						formatter: "{b}<br/>{a} : {c}辆 {d}%",
					},
					//修改图表颜色
					color: this.colorList,

					//graphic控制环形中间出现字，其中有两个对象，就是两行字
					graphic: [{
							type: "text", //控制内容为文本文字
							left: "center",
							top: "95px", //调整距离盒子高处的位置
							style: {
								fill: "#1cffff", //控制字体颜色
								text: this.tsscData[0].total || 0, //控制第一行字显示内容
								fontSize: 20,
							},
						},
						{
							type: "text",
							left: "center",
							top: "125px",
							z: 10,
							style: {
								text: "停车总数(辆)",
								fontSize: 14,
								fill: "#FFFFFF",
							},
						},
					],
					series: [{
						name: "停车总数(辆)",
						type: "pie",
						radius: ["50", "80%"],
						center: ["50%", "45%"],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: "center",
						},
						labelLine: {
							show: false,
						},
						data: this.tsscData,
					}, ],
				};
			},
			getoptions5() {
				this.options5 = {
					//hover气泡
					tooltip: {
						trigger: "item",
						position: "right",
						formatter: "{b}<br/>{a} : {c}辆 {d}%",
					},
					//修改图表颜色
					color: this.colorList,

					//graphic控制环形中间出现字，其中有两个对象，就是两行字
					graphic: [{
							type: "text", //控制内容为文本文字
							left: "center",
							top: "95px", //调整距离盒子高处的位置
							style: {
								fill: "#1cffff", //控制字体颜色
								text: this.djqTotal || 0, //控制第一行字显示内容
								fontSize: 20,
							},
						},
						{
							type: "text",
							left: "center",
							top: "125px",
							z: 10,
							style: {
								text: "总计(辆)",
								fontSize: 14,
								fill: "#FFFFFF",
							},
						},
					],
					series: [{
						name: "合计：",
						type: "pie",
						radius: ["40%", "70%"],
						// center: ["50%", "48%"],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: "center",
						},
						labelLine: {
							show: false,
						},
						data: this.djqAreaData,
					}, {
						name: '合计：',
						type: 'pie',
						radius: ['80%', '90%'],
						// center: ["50%", "48%"],
						labelLine: {
							show: false,
						},
						label: {
							show: false,
						},
						data: this.djqScenicData
					}],
				};

			}
		},
		/**加载完组件时执行(加载完成之后执行)*/
		mounted() {},
	}
</script>

<style lang="scss" scoped>
	.parking-box {
		.park-box {
			width: 909px;
			height: 424px;
			background: url("../assets/parking/9-bg@2x.png") no-repeat;
			background-size: 100% 100%;
			padding: 33px;

			.left-img {
				width: 288px;
				height: 48px;

				.title-img {
					width: 100%;
					height: 100%;
				}
			}

			.info-box {
				margin-top: 30px;

				.info1-box {
					width: 248px;
					height: 268px;
				}

				.echart_r {
					width: 248px;
					display: flex;
					flex-wrap: wrap;
					padding-top: 10px;
					margin-left: 30px;

					.echart_r_box {
						width: 50%;
						margin-bottom: 22px;

						.echart_r_box1,
						.echart_r_box2 {
							height: 28px;
							line-height: 28px;
							padding-left: 18px;
							font-size: 16px;
							color: #9bb0cc;
						}

						.echart_r_box2 {
							margin-left: 2px;
							font-size: 16px;
							font-weight: 700;
							color: #1CFFFF;
							text-shadow: 0px 0px 4px 0px rgba(55, 236, 255, 0.6);

							span {
								color: #9bb0cc;
							}
						}
					}
				}

				.info2-box {
					width: 268px;
					height: 268px;
				}

				.info3-box {
					width: 268px;
					height: 268px;
				}
			}
		}

		.bottom-box {
			width: 600px;
			padding: 0;

			.box-head {
				padding: 32px;
			}

			.info-box {
				margin-top: 0 !important;
			}

			.info2-box {
				width: 248px !important;
				height: 248px !important;
			}

			.info3-box {
				width: 338px !important;
				height: 268px !important;

				.echart_r {
					width: 90% !important;
					margin: 0 !important;
				}
			}
		}
		
		.bottom3 {
			
			.echart-r-box-item {
				background: #14213b;
				border-radius: 2px;
				padding: 12px 20px;
				margin-right: 40px;
				font-size: 14px;
				
				.echart-r-box-item-box {
					display: flex;
					align-items: center;
					margin-bottom: 5px;
					
					.echart-r-box-item-color {
						width: 10px;
						height: 10px;
						background-color: #207bf0;
						border-radius: 2px;
						margin-right: 8px;
					}
					
					.echart-r-box-item-title {
						font-weight: 400;
						color: #ffffff;
					}
					
					.echart-r-box-item-num {
						font-weight: 700;
						color: #1cffff;
					}
					
					.echart-r-box-item-percent {
						font-weight: 400;
						color: #ffffff;
						margin-left: 5px;
					}
					
				}
				
			}
			
			.echart-r-box-top {
				margin-top: 20px;
			}
			
			.echart-r-box-bottom {
				margin-top: 12px;
			}
			
		}

	}

	.kj-box {
		background: url('../assets/kuaijie-box.png') no-repeat;
		background-size: 100% 100%;
		width: 96px;
		height: 33px;
		margin-left: 12px;
		cursor: pointer;

		p {
			text-align: center;
			line-height: 33px;
			font-size: 14px;
			font-weight: 400;
			color: #ffffff;
		}
	}

	.flex {
		display: flex;
	}

	.mt23 {
		margin-top: 23px;
	}

	.ac {
		align-items: center;
	}

	.jb {
		justify-content: space-between;
	}

	.fw {
		flex-wrap: wrap;
	}
</style>
<style lang="scss">
	.right-select .el-input__inner {
		height: 28px !important;
		line-height: 28px !important;
		background: #042C60 !important;
		border: none !important;
		color: #37ECFF !important;
	}

	.right-select .el-input__icon {
		line-height: 28px !important;
	}

	.right-select2 .el-input__inner {
		height: 28px !important;
		line-height: 28px !important;
		background: #042C60 !important;
		border: none !important;
		color: #37ECFF !important;
		width: 230px;
	}

	.bottom-box .el-input__inner {
		width: 200px;
	}

	.right-select2 .el-input__icon {
		line-height: 28px !important;
	}

	.all-date-box .el-input__inner,
	.el-range-input {
		height: 28px !important;
		line-height: 28px !important;
		background: #042c60 !important;
		border: none !important;
		color: #37ecff !important;
	}

	.all-date-box .el-input__icon {
		line-height: 28px !important;
	}

	.all-date-box .el-range__icon,
	.el-range__close-icon {
		line-height: 20px !important;
	}

	.all-date-box .el-range-separator {
		color: #37ecff !important;
	}

	.chooseYearBox .el-input__inner,
	.el-range-input {
		height: 28px !important;
		line-height: 28px !important;
	}

	.chooseYearBox .el-input__icon {
		line-height: 28px !important;
	}

	.chooseYearBox .el-range__icon,
	.el-range__close-icon {
		line-height: 20px !important;
	}

	.is-selected {
		color: #1989FA;
	}

	.calendar-info {
		font-size: 12px;
	}

	.el-checkbox {
		margin: 0 5px 5px 0;
	}

	.el-checkbox.is-bordered+.el-checkbox.is-bordered {
		margin-left: 0;
	}

	// .popper-select {
	// 	li[aria-haspopup="true"] {
	// 		.el-checkbox {
	// 			display: none;
	// 		}
	// 	}
	// }
</style>